<template>

	<div class="customer-service-page-container">
		<TopicDetailModal v-if="showTopicDetail === true" @close="onTopicDetailClose" @ticketUpdated="refreshAllTopics"
		                  :ticketItem="this.selectedItem"></TopicDetailModal>
		<ConfirmModal v-if="showConfirmModal===true" @cancel="onConfirmModalCancel" @confirm="onConfirmModalConfirm"
		              :confirm-msg="this.confirmMsg"></ConfirmModal>
		<div class="cs-view-switch-container">
			<div class="cs-view-switch-wrapper">
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'vpn'}"
				     @click="this.onViewSwitcherClicked('vpn')">类目一
				</div>
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'airport'}"
				     @click="this.onViewSwitcherClicked('airport')">类目二
				</div>
			</div>
		</div>
		<div class="cs-main-container">
			<div class="btn-container">
				<a-pagination v-model:current="this.page" simple v-model:pageSize="this.pageSize"
				              :total="this.totalCount" @change="onVpnPageChanged" style="padding: 0.8em 0;"
				              :showSizeChanger="false"/>

				<div class="header-btn" @click="onRefreshBtnClicked">刷新</div>
			</div>

			<div class="list-container">
				<template v-for="item in this.vpnTopicList" :key="item.feedbackTopicId">
					<div class="item-container" @click="onItemClick(item)">
						<div class="item-title-container">
							<div class="item-title">
								{{ this.formatItemTitle(item.userDescription) }}
							</div>
							<img v-if="item?.isClosed !== true" src="../images/close_topic.svg" alt="close topic" @click="onCloseTicket(item, $event)">
						</div>

						<div class="item-email">
							{{ item.email }}
						</div>
						<div class="item-email">
							状态：<span :style="this.getTicketStatusStyle(item)">{{ item.topicState }}</span>
						</div>
						<div class="item-time">
							创建时间：{{ item.createdTimeString }}
						</div>
						<div class="item-time">
							更新时间：{{ item.updatedTimeString }}
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>

</template>

<script>
import {
	getRequestAsync,
	hideLoading,
	postRequestAsync,
	showLoading,
	convertTimestampToTimeString,
	verifyFp
} from "@/utils/methods";
import TopicDetailModal from "../components/TopicDetailModal.vue";
import ConfirmModal from "../components/ConfirmModal.vue";

export default {
	name: "CustomerServicePage",
	components: {
		TopicDetailModal,
		ConfirmModal
	},
	data() {
		return {
			vpnTopicList: [],
			page: 1,
			pageSize: 20,
			totalCount: 0,
			currentViewSwitcher: 'vpn',
			showTopicDetail: false,
			selectedItem: null,
			showConfirmModal: false,
			confirmMsg: '',
			confirmModalType: null
		}
	},
	async beforeMount() {
		this.isLogin = await this.checkLogin()
		if (this.isLogin) {
			await this.getAllVpnTopics(false)
		}
	},
	methods: {
		async onRefreshBtnClicked() {
			await this.getAllVpnTopics(true)

		},
		async checkLogin() {
			showLoading()
			const isVerified = await verifyFp()
			hideLoading()
			return isVerified
		},
		async getAllVpnTopics(isManual = false) {
			showLoading()
			const result = await postRequestAsync('admin/cs/getVpnAllTopics', {page: this.page})
			hideLoading()
			if (result?.code === 200 && result?.msg?.list) {
				this.vpnTopicList = result?.msg?.list
				this.totalCount = result?.msg?.totalCount
				if (isManual === true) {
					this.$message.success('刷新成功')
				}
			}
		},
		async refreshAllTopics() {
			await this.getAllVpnTopics()
		},
		async onTopicItemClicked(item) {
			showLoading()
			const result = await postRequestAsync('admin/cs/getTopicDetail', {
				topicId: item.feedbackTopicId,
				ticketId: item.ticketId
			})
			hideLoading()
			if (result.code === 200) {
				this.currentDisplayChat = result.msg
				this.currentDisplayTopic = item
				this.showMenuMobile = false
				if (result?.extra) {
					this.userReferByCode = result?.extra?.referCode || null
					this.userReferByEmail = result?.extra?.referByEmail || null
					this.userRegisterTime = result?.extra?.registerTime ? convertTimestampToTimeString(result?.extra?.registerTime) : null
					this.userPlanExpireTime = result?.extra?.planExpireTime ? convertTimestampToTimeString(result?.extra?.planExpireTime) : null
					this.usrePaymentRecords = result?.extra?.payments
				}
			} else {
				this.$message.error('获取反馈信息失败');
			}
		},
		getUserPaymentsDisplay() {
			if (Array.isArray(this.usrePaymentRecords) && this.usrePaymentRecords?.length > 0) {
				return `${this.usrePaymentRecords?.length}笔`
			}
			return '无'
		},
		async sendMsgReply() {
			if (this.currentDisplayTopic && this.currentDisplayTopic.ticketId) {
				await this.replyAirportTicket(this.currentDisplayTopic.ticketId)
				return
			}
			if (!this.currentDisplayTopic || !this.currentDisplayTopic.feedbackTopicId) {
				this.$message.error('请选择一个反馈');
				return
			}
			if (!this.toSendMsg) {
				this.$message.error('请输入信息');
				return
			}
			showLoading()
			const result = await postRequestAsync('customerServicePanel/sendTopicReply', {
				topicId: this.currentDisplayTopic.feedbackTopicId,
				msg: this.toSendMsg
			})
			hideLoading()
			if (result.code === 200) {
				debugger
				this.$message.success('发送成功');
				this.toSendMsg = ''
				this.currentDisplayChat = this.msg
			} else {
				this.$message.error('发送失败');
			}
		},
		async replyAirportTicket(ticketId) {
			showLoading()
			const result = await postRequestAsync('airport/ticket/replyAirportTicket', {
				ticketId,
				replyMsg: this.toSendMsg
			})
			hideLoading()

			if (result.code === 200) {
				debugger
				this.$message.success('发送成功');
				this.currentDisplayTopic = result.msg
			}
		},
		onShowMenuClicked() {
			console.log('onShowMenuClicked')
			this.showMenuMobile = true
		},
		onCloseMenuClicked() {
			this.showMenuMobile = false
		},
		async onVpnPageChanged(data) {

			this.page = data
			await this.getAllVpnTopics()

		},
		formatItemTitle(text) {
			if (!text) {
				return ''
			}
			if (text.length <= 10) {
				return text
			}
			return `${text.slice(0, 10)}...`;
		},

		getTicketStatusStyle(item) {
			if (item.topicState === '已回复') {
				return {
					'color': 'green'
				}
			}
			if (item.topicState === '等待回复') {
				return {
					'color': 'red'
				}
			}

		},
		onViewSwitcherClicked(viewType) {
			this.currentViewSwitcher = viewType
		},
		onItemClick(item) {
			this.showTopicDetail = true
			this.selectedItem = item
		},
		onTopicDetailClose() {
			this.showTopicDetail = false
		},
		onConfirmModalCancel() {
			this.showConfirmModal = false
		},
		async onConfirmModalConfirm() {
			this.showConfirmModal = false
			if (this.confirmModalType === 'CLOSE_TICKET') {
				await this.doCloseTicket()
			}
		},
		async doCloseTicket() {
			showLoading()
			const result = await postRequestAsync('admin/cs/closeVpnTicket', {
				ticketId: this.selectedItem?.feedbackTopicId
			})
			hideLoading()

			if (result.code === 200) {
				this.$message.success('发送成功');
				await this.getAllVpnTopics()
			}

		},
		onCloseTicket(item, event) {
			event.stopPropagation()
			this.confirmMsg = '确认关闭工单？'
			this.showConfirmModal = true
			this.confirmModalType = 'CLOSE_TICKET'
			this.selectedItem = item
		}
	}
}
</script>

<style scoped>
.customer-service-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;

	padding: 1em 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}


@media only screen and (max-width: 900px) {

}

.cs-main-container {
	display: flex;
	flex-direction: column;


	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	flex: 1;
}

.list-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	padding: 0 1em;
}

.item-title {
	color: #E6D5B8;
	font-size: 1.2em;
	width: 100%;
	text-align: start;
}

.item-email {
	color: rgba(230, 213, 184, 0.6);
	font-size: 1em;
	width: 100%;
	text-align: start;
}

.item-time {
	color: rgba(230, 213, 184, 0.6);
	font-size: 1em;
	width: 100%;
	text-align: start;
}

.item-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 0.4em;
	box-sizing: border-box;
	border: 1px solid rgba(230, 213, 184, 0.6);
	border-radius: 0.4em;
	margin-bottom: 1em;
	cursor: pointer;
}

.item-container:hover {
	transform: scale(1.01);
}

.tab-btn-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.cs-view-switch-btn {
	color: #FF8900;
	border: 1px solid #FF8900;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	margin: 0 0.4em;
}

.cs-view-switch-btn.active {
	color: #000000;
	background-color: #FF8900;
}

.cs-view-switch-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}


.cs-view-switch-wrapper {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.item-title-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.btn-container{
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0 1em;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
}

.header-btn{
	color: #E6D5B8;
	border: 1px solid #E6D5B8;
	padding: 0.2em 0.4em;
	font-size: 1em;
	line-height: 1em;
	height: fit-content;
	border-radius: 0.2em;
	cursor: pointer;
}
</style>